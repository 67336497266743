import React, { useEffect, useState } from "react";
import { motion, useMotionValue, useSpring } from "framer-motion";

// css
import * as styles from "./cursor.module.css";

const Cursor = () => {
  // const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hidden, setHidden] = useState(false);
  const [click, setClick] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [project, setProject] = useState(false);

  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 40, stiffness: 600 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  let animate;

  if ( click ) animate = { scale: .16666667 }
  else if ( project && hovering ) animate = { scale: 1 }
  else if ( !project && hovering ) animate = { scale: .75 }
  else if ( hidden ) animate = { scale: 0 }
  else animate = { scale: .20833333 }

  useEffect(() => {
    const move = (e) => {
      cursorX.set(e.clientX);
      cursorY.set(e.clientY);
    }

    const leave = () => setHidden(true);
    const enter = () => setHidden(false);
    const down = () => setClick(true);
    const up = () => setClick(false);

    const addEventListeners = () => {
      document.addEventListener("mousemove", move);
      document.addEventListener("mouseover", enter);
      document.addEventListener("mouseout", leave);
      document.addEventListener("mousedown", down);
      document.addEventListener("mouseup", up);
    }

    const removeEventListeners = () => {
      document.removeEventListener("mousemove", move);
      document.removeEventListener("mouseover", enter);
      document.removeEventListener("mouseout", leave);
      document.removeEventListener("mousedown", down);
      document.removeEventListener("mouseup", up);
    }

    const addLinkEvents = () => {
      document.querySelectorAll("a").forEach((el) => {
        el.addEventListener("mouseover", () => setHovering(true));
        el.addEventListener("mouseout", () => setHovering(false));
      });
      document.querySelectorAll("video").forEach((el) => {
        el.addEventListener("mouseover", () => setHidden(true));
        el.addEventListener("mouseout", () => setHidden(false));
      });
      document.querySelectorAll(".project").forEach((el) => {
        el.addEventListener("mouseover", () => setProject(true));
        el.addEventListener("mouseout", () => setProject(false));
      });
      document.querySelectorAll("button").forEach((el) => {
        el.addEventListener("mouseover", () => setHovering(true));
        el.addEventListener("mouseout", () => setHovering(false));
      });
    }

    addEventListeners();
    addLinkEvents();
    return () => removeEventListeners();
  }, []);

  return (
    <motion.div
      className={
        `cursor ${styles.cursor} ` +
        (hidden ? `${styles.hidden}` : `${styles.visible}`)
      }
      animate={animate}
      style={{
        translateY: cursorYSpring,
        translateX: cursorXSpring,
      }}
    >
      <motion.span className={`${styles.text} w-12 h-12 block text-f7 font-title font-title-wide-medium lowercase text-daintree text-center ` + (project ? `${styles.visible}` : `${styles.hidden}`)}>View</motion.span>
    </motion.div>
  )
}

export default Cursor;
