/* eslint react/prop-types: 0 */
import "./src/css/global.css";
import React from "react";
import Base from "./src/components/layout/base";
import { Provider } from "./src/components/layout/identity";

export const wrapPageElement = ({ element, props }) => {
  return <Base {...props}>{element}</Base>;
}

export const wrapRootElement = ({ element }) => {
  return <Provider>{element}</Provider>
}
