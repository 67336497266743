import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, useStaticQuery } from "gatsby";

// css
import * as styles from "./logo.module.css";

const Logo = (props) => {
  const { site } = useStaticQuery(graphql`
    query LogoQuery {
      site {
        siteMetadata {
          title
          short_name
        }
      }
    }
  `);
  const logoStyles = `${props.className} block text-f8 md:text-f6 font-title font-title-wide-medium`;

  return (
    <React.Fragment>
      <div className={`${styles.logo} flex items-center content-center flex-row`}>
        <Link to="/" className={logoStyles}>
          {site.siteMetadata.short_name}.
        </Link>
      </div>
    </React.Fragment>
  )
}

Logo.propTypes = {
  className: PropTypes.node,
}

export default Logo;
