import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

// components
import Logo from "@components/nav/logo";

// style
import * as styles from "./profile.module.css";

// svg
import Safari from "@images/safari.inline.svg";
import MagnifyingGlass from "@images/magnifying-glass.inline.svg";
import Terminal from "@images/terminal.inline.svg";
import Pen from "@images/pen.square.inline.svg";

const Profile = (props) => {
  const containerStyles = `${props.className}`;
  const contentStyles = `${styles.content} relative`;

  const controls = useAnimation();
  const variants = {
    hidden: { opacity: 0, y: 16 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.05,
      },
    },
  };
  const [ref, inView] = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  function setTitle() {
    return {
      __html: `I’m Steve McKinney, a&nbsp;UX designer based in Manchester`,
    };
  }

  return (
    <React.Fragment>
      <motion.aside
        className={containerStyles}
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={variants}
      >
        <div className={contentStyles}>
          <Logo className="mb-8" />
          <h2
            className={`${styles.title} l-f1 text-pearl content mb-12 leading-[.875]`}
            dangerouslySetInnerHTML={setTitle()}
          />
          <h3
            className="block text-f6 text-pearl lowercase font-title font-title-wide-medium mb-4"
            dangerouslySetInnerHTML={{ __html: `Let’s talk design` }}
          />
          <ul className="flex mdMax:flex-col font-bold text-f6 md:text-f5 p-0 m-0 gap-2 md:gap-8">
            <li>
              <a
                href="https://twitter.com/irsteve"
                className="text-melon hover:text-jasmine"
              >
                x.com
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/steve-mckinney-5b5836102"
                className="text-melon hover:text-jasmine"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                rel="me"
                href="https://mastodon.design/@steve"
                className="text-melon hover:text-jasmine"
              >
                Mastodon
              </a>
            </li>
            <li>
              <a
                href="https://github.com/stevemckinney"
                className="text-melon hover:text-jasmine"
              >
                Github
              </a>
            </li>
            <li>
              <a
                href="https://read.cv/stevemckinney"
                className="text-melon hover:text-jasmine"
              >
                Read.cv
              </a>
            </li>
          </ul>
          <Pen className={`absolute bottom-0 right-0 lg:hidden`} />
        </div>
        <div
          className={`${styles.image} flex flex-col gap-2 lgMax:hidden`}
          role="presentation"
        >
          <div className="flex gap-2">
            <MagnifyingGlass className={`${styles.figma}`} />
            <Pen className={`${styles.pen}`} />
          </div>
          <div className="flex justify-end gap-2">
            <Terminal className={`${styles.terminal}`} />
            <Safari className={`${styles.safari}`} />
          </div>
        </div>
      </motion.aside>
    </React.Fragment>
  );
};

Profile.defaultProps = {
  className: `profile`,
};

Profile.propTypes = {
  className: PropTypes.node,
};

export default Profile;
