import React from "react";
import PropTypes from "prop-types";
import { isBrowser } from "react-device-detect";
import { LayoutGroup } from "framer-motion";

// components
import Header from "@components/header";
import Profile from "@components/sections/profile";
import Footer from "@components/footer";
import Cursor from "@components/cursor";

function Base({ children }) {
  return (
    <React.Fragment>
      {isBrowser && <Cursor />}

      <LayoutGroup layoutId={`container`}>
        <Header className="header" key={`global-header`} />
        {children}
        <Profile
          className="py-36 grid layout  items-end mdMax:justify-center md:items-center"
          key={`global-profile`}
        />
        <Footer key={`global-footer`} />
      </LayoutGroup>
    </React.Fragment>
  );
}

Base.defaultProps = {
  className: "grid layout",
};

Base.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node.isRequired,
};

export default Base;
