import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

// components
import Bullet from "@components/content/bullet";

// icons
import Bee from "@images/icons/bee.inline.svg"

// style
import * as styles from "./footer.module.css";

const Footer = (props) => {
  const { site } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
          short_name
        }
      }
    }
  `);
  const footerStyles = `${props.className} ${styles.footer} grid layout gap-y-4 items-center mdMax:justify-center pb-48 md:pb-16`;

  return (
    <React.Fragment>
      <footer className={footerStyles}>
        <p className="col-content-start col-content-end md:col-end-7 lg:col-end-8 mb-0 text-f6 text-pearl lowercase font-title font-title-wide-medium flex flex-col md:flex-row md:items-center gap-4 max-w-[24ch] md:max-w-[30ch] xl:max-w-full">
        <span className="w-10 h-10 bg-melon flex flex-shrink-0 items-center justify-center rounded-full inline-block"><Bee /></span> designed & built with figma, gatsby & netlify</p>
        <p className="col-content-start col-content-end md:col-start-7 lg:col-start-8 mb-0 text-f6 md:text-right text-pearl lowercase font-title font-title-wide-medium">
          © {new Date().getFullYear()}<Bullet className="bg-madison mx-3" />{site.siteMetadata.title}
        </p>
      </footer>
    </React.Fragment>
  );
}

Footer.defaultProps = {
  className: "footer"
}

Footer.propTypes = {
  className: PropTypes.node,
}

export default Footer;
